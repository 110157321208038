import { SignService } from '@/Services/SignService'
import { KycFileCCSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'
/* eslint-disable */
export const sendFileInfo = (data) => {
  return new Promise(function (resolve, reject) {
    let signServices = new SignService(data.key, localStorage.getItem('user_id'))
    let options = {
      form_scanned_copy_file_info: {},
      passport_file_info: {},
      selfie_file_info: {},
      utility_bill_file_info: {}
    }
    delete data.key
    if (data.step === 2) {
      delete data.step
      options.passport_file_info = signServices.createSignedRequest(actionSignature(KycFileCCSignatures.channel, KycFileCCSignatures.chaincode, KycFileCCSignatures.action.addFileInfo), data.passport_file_info)
      options.utility_bill_file_info = signServices.createSignedRequest(actionSignature(KycFileCCSignatures.channel, KycFileCCSignatures.chaincode, KycFileCCSignatures.action.addFileInfo), data.utility_bill_file_info)
      options.form_scanned_copy_file_info = signServices.createSignedRequest(actionSignature(KycFileCCSignatures.channel, KycFileCCSignatures.chaincode, KycFileCCSignatures.action.addFileInfo), data.form_scanned_copy_file_info)

      options.selfie_file_info = null
    } else if (data.step === 3) {
      delete data.step
    }
    resolve(options)
    reject(Error)
  })
}
