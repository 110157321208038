/* eslint-disable */
import { currencies } from '@/models/Currencies'
import { createDateOfTimeStamp } from '@/Methods/GlobalMethods'
import OfferModel from '@/models/requests/OfferModel'
import { reactive } from 'vue'

const exportExponentialToString = (digits, amountScale) => {
  let exportString = '';

  let str = String(digits)
  let order = str.replace(/.+e-/gm, '')
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}
let flagCource = false
const courseCalculation = (surrendered, requested) => {
  let course = 0
  if (flagCource) {
    course = surrendered / requested
  } else {
    course = requested / surrendered
  }
  course = course.toFixed(16)
  while (course.endsWith('0')) {
    course = course.substring(0, course.length - 1)
  }
  if (course.endsWith('.')) {
    course = course.substring(0, course.length - 1)
  }
  return course
}

let convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
    // amount = amount.toFixed(numb);
  }
  return String(amount)
}

let sortHistory = (a, b) => {
  if (Number(a.timestamp) < Number(b.timestamp)) {
    return 1
  } else {
    return -1
  }
}

const sortByRequestTimestamp = (a, b) => b.request?.in?.timestamp - a.request?.in?.timestamp

export const buildOfferObjectByDate = (offerArr, offerObj = {}) => {
  const isSingleOffer = offerArr.length === 1
  return reactive(offerArr.reduce((accOfferObj, offer) => {
    if (offerObj[offer.date]) offerObj[offer.date][isSingleOffer ? 'unshift' : 'push'](offer)
    else offerObj[offer.date] = [offer]
    return offerObj
  }, offerObj))
}

export const getStateOffersName = (request, offerStatus) => {
  const offerTypePrefix = request.input_output_type === 'INPUT' ? 'input' : 'output'
  return offerStatus ? `${offerTypePrefix}HistoryOffers` : `${offerTypePrefix}Offers`
}

export const createOfferListV2 = offersObject => {
  const offers = {
    inputOffers: [],
    inputHistoryOffers: [],
    outputOffers: [],
    outputHistoryOffers: [],
  }
  const offersByDates = {
    inputOffers: {},
    inputHistoryOffers: {},
    outputOffers: {},
    outputHistoryOffers: {},
  }
  for (const operation of Object.values(offersObject || {}).sort(sortByRequestTimestamp)) {
    const { request, offer_status } = operation
    offers[getStateOffersName(request, offer_status)].push(new OfferModel(operation))
  }

  Object.keys(offers).forEach(listName => {
    // objectByDate.sortedDates = Object.keys(objectByDate).sort((aDate, bDate) => new Date(bDate) - new Date(aDate))
    offersByDates[listName] = buildOfferObjectByDate(offers[listName])
  })
  return offersByDates
}

export const createOffersList = offersObject => {
  let countNew = 0
  let inRequestList = []
  let inHistoryRequests = {}
  let outRequestsList = []
  let outHistoryRequest = {}
  let temporaryArrayForHistory = []

  /**
   * Создание списка входящих заявок
   */
  flagCource = true
  for (let operationId in offersObject) {
    const operation = offersObject[operationId].request
    if (!(!!offersObject[operationId].offer_status)) {
      if (operation.input_output_type === 'INPUT') {
        let id = operationId
        let requestedUser = operation.out.user_account_info.user_id
        let requestedCurrency = operation.out.amount.currency_type
        let requestedCurrencyName = currencies[operation.out.amount.currency_type]
        let requestedAmount = operation.out.amount.amount
        let surrenderedCurrency = operation.in.amount.currency_type
        let surrenderedCurrencyName = currencies[operation.in.amount.currency_type]
        let surrenderedAmount = operation.in.amount.amount
        let course = courseCalculation(Number(surrenderedAmount), Number(requestedAmount))
        let flag = false
        requestedAmount = convertAmount(requestedAmount)
        surrenderedAmount = convertAmount(surrenderedAmount)
        course = convertAmount(course)
        let request = {}
        request.user = requestedUser
        request.request = []
        let obj = {}
        obj.inCurrency = currencies[surrenderedCurrency]
        obj.outCurrency = currencies[requestedCurrency]
        obj.inAmount = surrenderedAmount
        obj.outAmount = requestedAmount
        obj.course = course
        obj.operationId = id
        obj.inCurrensyName = surrenderedCurrencyName
        obj.outCurrencyName = requestedCurrencyName
        obj.coursePair = currencies[requestedCurrency] + '/' + currencies[surrenderedCurrency]
        obj.flagCourse = true
        request.request.push(obj)
        for (let j = 0; j <= inRequestList.length; j++) {
          if (inRequestList[j]) {
            if (inRequestList[j].user === requestedUser) {
              flag = true
              countNew = j
            }
          }
        }
        if (flag) {
          inRequestList[countNew].request.push(obj)
        } else {
          inRequestList.push(request)
        }
      }
    }
  }
  flagCource = false

  /**
   * Создание списка исходящих заявок
   */
  for (let operationId in offersObject) {
    const operation = offersObject[operationId].request
    if (!(!!offersObject[operationId].offer_status)) {
      if (operation.input_output_type === 'OUTPUT') {
        let id = operationId
        let requestedUser = operation.in.user_account_info.user_id
        let requestedCurrency = operation.out.amount.currency_type
        let requestedCurrencyName = currencies[operation.out.amount.currency_type]
        let requestedAmount = operation.out.amount.amount
        let surrenderedCurrency = operation.in.amount.currency_type
        let surrenderedCurrencyName = currencies[operation.in.amount.currency_type]
        let surrenderedAmount = operation.in.amount.amount
        let course = courseCalculation(Number(surrenderedAmount), Number(requestedAmount))
        let flag = false
        requestedAmount = convertAmount(requestedAmount)
        surrenderedAmount = convertAmount(surrenderedAmount)
        course = convertAmount(course)
        let request = {}
        request.user = requestedUser
        request.request = []
        let obj = {}
        obj.inCurrency = currencies[surrenderedCurrency]
        obj.outCurrency = currencies[requestedCurrency]
        obj.inAmount = surrenderedAmount
        obj.outAmount = requestedAmount
        obj.course = course
        obj.operationId = id
        obj.inCurrensyName = surrenderedCurrencyName
        obj.outCurrencyName = requestedCurrencyName
        obj.coursePair = currencies[surrenderedCurrency] + '/' + currencies[requestedCurrency]
        obj.flagCourse = true
        request.request.push(obj)
        for (let j = 0; j <= outRequestsList.length; j++) {
          if (outRequestsList[j]) {
            if (outRequestsList[j].user === requestedUser) {
              flag = true
              countNew = j
            }
          }
        }
        if (flag) {
          outRequestsList[countNew].request.push(obj)
        } else {
          outRequestsList.push(request)
        }
      }
    }
  }

  /**
   * Создание истории входящих заявок
   */
  flagCource = true
  for (let offer in offersObject) {
    if (offersObject[offer].request.input_output_type === 'INPUT') {
      // eslint-disable-next-line
      if ((!!offersObject[offer].offer_status)) {
        if (offersObject[offer].offer_status.indexOf('OfferCompleted') !== -1 && offersObject[offer].offer_status.indexOf('OfferCanceled') === -1
        ) {
          let obj = offersObject[offer].request
          temporaryArrayForHistory.push({
            outUser: obj.out.user_account_info.user_id,
            inAmount: convertAmount(obj.in.amount.amount),
            inCurrency: obj.in.amount.currency_type,
            outAmount: convertAmount(obj.out.amount.amount),
            inCurrencyName: currencies[obj.in.amount.currency_type],
            outCurrency: obj.out.amount.currency_type,
            outCurrencyName: currencies[obj.out.amount.currency_type],
            timestamp: obj.out.timestamp,
            time: createDateOfTimeStamp(obj.out.timestamp),
            course: courseCalculation(Number(obj.out.amount.amount), Number(obj.in.amount.amount)),
            coursePair: currencies[obj.out.amount.currency_type] + '/' + currencies[obj.in.amount.currency_type],
            id: obj.in.operation_id,
            flagCourse: true,
            status: offersObject[offer].offer_status.indexOf('OfferAccepted') !== -1
              ? `accepted`
              : offersObject[offer].offer_status.indexOf('OfferRejected') !== -1
                ? `rejected`
                : 'canceled'
          })
        }
      }
    }
  }
  temporaryArrayForHistory.sort(sortHistory)
  for (let i = 0; i < temporaryArrayForHistory.length; i++) {
    let fe = false
    for (let element in inHistoryRequests) {
      if (element === temporaryArrayForHistory[i].time) {
        inHistoryRequests[element].push(temporaryArrayForHistory[i])
        fe = true
        break
      }
    }
    if (!fe) {
      inHistoryRequests[temporaryArrayForHistory[i].time] = []
      inHistoryRequests[temporaryArrayForHistory[i].time].push(temporaryArrayForHistory[i])
    }
  }
  flagCource = false

  /**
   * Создание истории исходящих заявок
   */
  temporaryArrayForHistory = []
  for (let offer in offersObject) {
    if (offersObject[offer].request.input_output_type === 'OUTPUT') {
      // eslint-disable-next-line
      if ((!!offersObject[offer].offer_status)) {
        if (offersObject[offer].offer_status.indexOf('OfferCompleted') !== -1 || offersObject[offer].offer_status.indexOf('OfferCanceled') !== -1
        ) {
          let obj = offersObject[offer].request
          temporaryArrayForHistory.push({
            outUser: obj.in.user_account_info.user_id,
            inAmount: obj.in.amount.amount,
            inCurrency: obj.in.amount.currency_type,
            inCurrencyName: currencies[obj.in.amount.currency_type],
            outAmount: obj.out.amount.amount,
            outCurrency: obj.out.amount.currency_type,
            outCurrencyName: currencies[obj.out.amount.currency_type],
            timestamp: obj.out.timestamp,
            time: createDateOfTimeStamp(obj.out.timestamp),
            course: courseCalculation(Number(obj.out.amount.amount), Number(obj.in.amount.amount)),
            coursePair: currencies[obj.out.amount.currency_type] + '/' + currencies[obj.in.amount.currency_type],
            id: obj.in.operation_id,
            flagCourse: true,
            status: offersObject[offer].offer_status.indexOf('OfferAccepted') !== -1
              ? `accepted`
              : offersObject[offer].offer_status.indexOf('OfferRejected') !== -1
                ? `rejected`
                : 'canceled'
          })
        }
      }
    }
  }
  temporaryArrayForHistory.sort(sortHistory)
  for (let i = 0; i < temporaryArrayForHistory.length; i++) {
    let fe = false
    for (let element in outHistoryRequest) {
      if (element === temporaryArrayForHistory[i].time) {
        outHistoryRequest[element].push(temporaryArrayForHistory[i])
        fe = true
        break
      }
    }
    if (!fe) {
      outHistoryRequest[temporaryArrayForHistory[i].time] = []
      outHistoryRequest[temporaryArrayForHistory[i].time].push(temporaryArrayForHistory[i])
    }
  }

  return { inRequestList: inRequestList, outRequestsList: outRequestsList, inHistoryRequests: inHistoryRequests, outHistoryRequest: outHistoryRequest }
}
