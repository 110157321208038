import { SignService } from '@/Services/SignService'
import { OfferStatusSignatures, TransactionSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'
import Store from '@/store'

/* eslint-disable camelcase */
export let reject = (action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    let signService = new SignService(key, localStorage.user_id)
    let tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
    let tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.unlockFundsOnAccount), operation_id)
    let final_option = {
      action_set_offer_status_by_operation_id: tempOption1,
      transfer: tempOption3
    }
    resolve(final_option)
  })
}

export let accept = (block_option, action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    Store.dispatch('getTransactions')
      .then(response => {
        for (let i = 0; i < response.balance.length; i++) {
          let walletInfo = response.balance[i]
          if (walletInfo.wallet === block_option.in_user.wallet_id) {
            for (let account in walletInfo.accounts) {
              if (account === block_option.in_user.account_id) {
                let flag = true
                for (let j = 0; j < walletInfo.accounts[account].amount.length; j++) {
                  let thisAmount = walletInfo.accounts[account].amount[j]
                  if (thisAmount.currency === block_option.amount.currency_type) {
                    if (Number(thisAmount.amount) < Number(block_option.amount.amount)) {
                      throw new Error('Упс')
                    } else {
                      flag = true
                      break
                    }
                  } else {
                    flag = false
                  }
                }
                if (!flag) {
                  throw new Error('Упс')
                }
              }
            }
          }
        }
      })
      .then(() => {
        let signService = new SignService(key, localStorage.user_id)
        let tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
        let tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.transferFunds), operation_id)
        let sign_block_option = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockFundsOnAccount), block_option)
        let final_option = {
          action_set_offer_status_by_operation_id: tempOption1,
          transfer: tempOption3,
          txn_funds_lock_input: sign_block_option
        }
        resolve(final_option)
      })
  })
}

export let cancel = (action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    let signService = new SignService(key, localStorage.user_id)
    let tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
    let tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.unlockFundsOnAccount), operation_id)
    let final_option = {
      action_set_offer_status_by_operation_id: tempOption1,
      transfer: tempOption3
    }
    resolve(final_option)
  })
}
/* eslint-enable camelcase */
