import Decimal from 'decimal.js'
import { getCurrencyFullName } from '@/models/Currencies'
import { isCommissionFromSender } from '@/models/ProcessTypes'

const ZERO_COMMISSION = {
  min: 0,
  max: 0,
  commission_percent: 0
}

export const getCommissionByProcessType = (commissionDto, processType) => {
  let commission = { partnerCommission: null, cryptoenterCommission: null }
  commissionDto.commission_list.forEach(item => {
    if (item.process_type === processType) {
      commission.partnerCommission = item
    }
  })
  if (!commission.partnerCommission) {
    commission.partnerCommission = ZERO_COMMISSION
  }
  commissionDto.cryptoenter_commission_list.forEach(item => {
    if (item.process_type === processType) {
      commission.cryptoenterCommission = item
    }
  })
  return commission
}

const HUNDRED = new Decimal(100)

export const computeCommissionAmount = (amount, commission, commissionCurrencyExchangeRate) => {
  let rate = new Decimal(commissionCurrencyExchangeRate)
  let comm = {
    percent: new Decimal(commission.commission_percent),
    min: new Decimal(commission.min),
    max: new Decimal(commission.max)
  }
  let result = new Decimal(amount).mul(rate).mul(comm.percent).div(HUNDRED)
  result = result.lt(comm.min)
    ? comm.min
    : result.gt(comm.max)
      ? comm.max
      : result
  if (result.isNaN()) {
    throw String('something gone wrong, result commission amount is NaN')
  }
  return result
}

const ZERO = new Decimal(0)

export const computeTotalCommissionAmount = (amount, ceComm, orgComm, lionExchangeRate, lionDecimalPlaces) => {
  let ceCommAmount = computeCommissionAmount(amount, ceComm, lionExchangeRate)
  let orgCommAmount = orgComm ? computeCommissionAmount(amount, orgComm, lionExchangeRate) : ZERO
  let commAmount = ceCommAmount.add(orgCommAmount)
  // rounding up
  return commAmount.toDecimalPlaces(Number(lionDecimalPlaces), 0)
}

const INSUFFICIENT_FUNDS = 'insufficientFunds'
export const INSUFFICIENT_FUNDS_FOR_COMM = 'insufficientFundsForCommission'

// eslint-disable-next-line no-unused-vars
function getAmountFromWalletBalance (walletBalance, currencyName) {
  for (let wallet of walletBalance) {
    for (let a in wallet.accounts) {
      for (let curr of wallet.accounts[a].amount) {
        if (getCurrencyFullName(curr.currencyName) === getCurrencyFullName(currencyName)) {
          return curr.amount
        }
      }
    }
  }
}

function normalizeBalanceCurrencyNames (balance) {
  let normalized = {}
  for (let curr in balance) {
    normalized[getCurrencyFullName(curr)] = balance[curr]
  }
  return normalized
}

export function validateTransferRequest (balance, transferCurrency, transferAmount, commissionCurrency, commission, commissionCurrencyExchangeRate, processType) {
  let result = {
    errCodes: [],
    hasErrors () {
      return this.errCodes && this.errCodes.length !== 0
    }
  }
  transferCurrency = getCurrencyFullName(transferCurrency)
  commissionCurrency = getCurrencyFullName(commissionCurrency)
  balance = normalizeBalanceCurrencyNames(balance)
  let isSenderCommission = isCommissionFromSender(processType)
  let balanceAmount = new Decimal(balance[transferCurrency] ?? 0)
  let transferDecAmount = new Decimal(transferAmount)
  if (isSenderCommission && transferCurrency === commissionCurrency) {
    let partnerCommAmount = computeCommissionAmount(transferAmount, commission.partnerCommission, 1)
    let cryptoenterCommAmount = computeCommissionAmount(transferAmount, commission.cryptoenterCommission, 1)
    if (balanceAmount.lt(transferDecAmount)) {
      result.errCodes.push(INSUFFICIENT_FUNDS)
    } else if (balanceAmount.lt(transferDecAmount.plus(partnerCommAmount).plus(cryptoenterCommAmount))) {
      result.errCodes.push(INSUFFICIENT_FUNDS_FOR_COMM)
    }
    return result
  }
  if (balanceAmount.lt(transferDecAmount)) {
    result.errCodes.push(INSUFFICIENT_FUNDS)
  }
  if (isSenderCommission) {
    let commissionCurrencyBalanceAmount = new Decimal(balance[commissionCurrency] || 0)
    let partnerCommAmount = computeCommissionAmount(transferAmount, commission.partnerCommission, commissionCurrencyExchangeRate)
    let cryptoenterCommAmount = computeCommissionAmount(transferAmount, commission.cryptoenterCommission, commissionCurrencyExchangeRate)
    if (commissionCurrencyBalanceAmount.lt(partnerCommAmount.plus(cryptoenterCommAmount))) {
      result.errCodes.push(INSUFFICIENT_FUNDS_FOR_COMM)
    }
  }
  return result
}
