import { currencies } from '@/models/Currencies.js'
import { SignService } from '@/Services/SignService'
import { WithdrawalOfferCCSignatures, TransactionSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const Withdrawal = dataForSend => {
  return new Promise(function (resolve, reject) {
    let gas = dataForSend.SenderGas
    for (let element in currencies) {
      if (currencies[element] === dataForSend.SenderCurrency) {
        dataForSend.SenderCurrency = element
      }
    }
    let txnBlock = {
      amount: {
        amount: dataForSend.SenderAmount,
        currency_type: dataForSend.SenderCurrency
      },
      out_user: {
        account_id: dataForSend.SenderAccount,
        user_id: localStorage.user_id,
        wallet_id: dataForSend.SenderWallet
      },
      process_type: dataForSend.ProcessType
    }
    let withBlock = {
      offer_record_status: 'OfferRecordStatusRequest',
      out_info: {
        account_id: dataForSend.SenderAccount,
        amount: {
          amount: dataForSend.SenderAmount,
          currency_type: dataForSend.SenderCurrency
        },
        user_id: localStorage.user_id,
        wallet_id: dataForSend.SenderWallet
      },
      withdrawal_account: dataForSend.SendingWallet
    }

    let signService = new SignService(dataForSend.key, localStorage.getItem('user_id'))
    let withSignature
    let finalOption = {
      txn_funds_lock_input: {},
      withdrawal_external_wallet: '',
      withdrawal_request: {}
    }
    switch (dataForSend.SenderCurrency) {
      case 'zcash':
      case 'litecoin':
      case 'dogecoin':
      case 'dash':
      case 'bitcoingold':
      case 'bitcoincash':
      case 'bitcoin':
        withSignature = signService.createSignedRequest(actionSignature(txnBlock.amount.currency_type + WithdrawalOfferCCSignatures.channel, txnBlock.amount.currency_type + WithdrawalOfferCCSignatures.chaincode, WithdrawalOfferCCSignatures.action.addOffer), withBlock)
        break
      case 'ethereum':
      case 'ethereumclassic':
      case 'tether':
      case 'lion':
        const actionType = 'ethereum'
        withSignature = signService.createSignedRequest(actionSignature(actionType + WithdrawalOfferCCSignatures.channel, actionType + WithdrawalOfferCCSignatures.chaincode, WithdrawalOfferCCSignatures.action.addOffer), withBlock)
        finalOption.withdrawal_external_txfee = gas
        break
    }
    let txnSignature = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockOutFundsOnAccount), txnBlock)
    finalOption.txn_funds_lock_input = txnSignature
    finalOption.withdrawal_external_wallet = dataForSend.SendingWallet
    finalOption.withdrawal_request = withSignature
    resolve(finalOption)
  })
}
